// REQUIRED - ANGULAR
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

// REQUIRED - LOCAL
import { AuthService } from '@app/_serv/auth.serv';

// HELPER - AUTH GUARD
@Injectable()
export class AuthGuard implements CanActivate {
	constructor(
		public auth:	AuthService,
		public rout:	Router
	) {}

	canActivate(): boolean {
		// if ( !this.auth.token() ) {
		// 	this.rout.navigateByUrl('/');
		// 	return false;
		// }

		return true;
	}
}



// @Injectable()
// export class AuthGuardService implements CanActivate {
//   constructor(
//     public auth: AuthService,
//     public router: Router
//   ) {}
//   canActivate(): boolean {
//     if (!this.auth.getToken()) {
//       this.router.navigateByUrl('/log-in');
//       return false;
//     }
//     return true;
//   }
// }

// // HELPER - AUTH GUARD
// @Injectable({ providedIn: 'root' })
// export class AuthCheck implements CanActivate {

// 	constructor(
// 		private auth_router:	Router,
// 		private auth_service:	AuthService
// 	) { }

// 	canActivate( auth_route: ActivatedRouteSnapshot, auth_state: RouterStateSnapshot ) {

// 		const auth_user = this.auth_service.auth_user_value;

// 		// NOTE: SUCCESSFUL AUTH DETECTED
// 		if ( auth_user ) {
// 			return true;
// 		}

// 		// NOTE: FAILURE DETECTED
// 		this.auth_router.navigate( [ '/' ], { queryParams: { returnUrl: auth_state.url } } );
// 		return false;
// 	}
// }
