// ? IMPORTS
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

// ? INTERNAL
import { AuthData } from '@app/_data/auth.data';

import { environment as env } from '@env/env';

interface AuthPing {
	time: number;
}

/* SERVICE - AUTH */
@Injectable({ providedIn: 'root' })
export class AuthService {
	constructor(private http: HttpClient) {}

	/* AUTH - PING */
	ping(): Observable<AuthPing> {
		return this.http.get<AuthPing>('/api').pipe(take(1));
	}

	/* AUTH - INIT */
	init(): Observable<AuthData> {
		return this.http.get<AuthData>('/api/auth').pipe(take(1));
	}
	/* AUTH - QUIT */
	quit(): Observable<AuthData> {
		return this.http.get<AuthData>('/api/auth/quit').pipe(take(1));
	}
	/* AUTH - ACCS */
	accs(): Observable<AuthData> {
		return this.http.get<AuthData>('/api/auth/accs').pipe(take(1));
	}
	/* AUTH - RFSH */
	rfsh(): Observable<AuthData> {
		return this.http.get<AuthData>('/api/auth/rfsh').pipe(take(1));
	}
	/* AUTH - SIGN */
	sign(mode: string, mail: string, pass: string): Observable<AuthData> {
		if (mode === 'in') {
			return this.http
				.post<AuthData>('/api/auth/accs', { mail, pass })
				.pipe(take(1));
		}
		if (mode === 'up') {
			return this.http
				.post<AuthData>('/api/auth', { mail, pass })
				.pipe(take(1));
		}
	}

	make(name:string, mail:string, pass:string): Observable<AuthData> {
		return this.http.post<AuthData>('/api/auth', { name, mail, pass }).pipe(take(1));
	};

	/** Mail Change Request
	 *  @param mail object containing both the current and desired email addresses
	 *  @param veri object containing verification tokens from both email addresses
	 **/
	mail(
		mail: { old: string; new: string },
		veri: { old: string; new: string }
	) {}
	/** Password Change Requet
	 *  @param pass object containing both the current and desired password
	 *  @returns boolean indiciated whether the password change was successful
	 *  @example pass({ old: 'LameOldPassword@!34', new: 'ShinyNewPassword@!56' })
	 **/
	pass(pass: { old: string; new: string }): Observable<any> {
		try {
			// * VALIDATE BOTH PASSWORD INPUTS MEET MINIMUMS
			if (true) {
				console.log(pass)
				return this.http.post<any>(`${env.API_AUTH}/pass`, pass).pipe( take(1) );
			}
			// ! CATCH INVALID INPUT
			return undefined;
		} catch {
			return undefined;
		}
	}
}
