// ? IMPORTS
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

/* FAIL = GENERIC FAILURE HANDLER */
@Component({ selector: 'app-fail', templateUrl: './fail.com.html', styleUrls: ['./fail.com.scss'] })
export class FailComponent implements OnInit {
	// * CONSTRUCTOR
	constructor(
		private rout: Router,
	) {}
	// * ON INIT
	ngOnInit() {}

	/* RETURN TO HOME */
	returnToHome(): void {
		this.rout.navigate(['/']);
	}
}
