// ? EXTERNAL
import { Injector, NgModule } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClientXsrfModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, CanActivate } from '@angular/router';

import { DateFnsModule } from 'ngx-date-fns';						// TODO replace with internal modules
import { ImageCropperModule } from 'ngx-image-cropper';				// TODO replace with internal modules

// ? INTERNAL
import { AppComponent } from '@app/app.component';
import { FailComponent } from '@app/**fail/fail.com';

import { AuthGuard } from '@app/_help/auth.help.guard';
import { AuthRequest, AuthXSRF } from '@app/_help/auth.help.request';
import { SortMenuPipe } from '@app/_help/sort.help';

import { AuthService } from '@app/_serv/auth.serv';
import { CoreIconWidget } from '@lib/core/core';

// MODULE - EXPORT
@NgModule({
	declarations: [
		// ? COMPONENTS
		AppComponent, 
		// ? OVERLAYS
		// AuthOverlay,
		// UserOverlay,
		// ? PIPES
		SortMenuPipe
	],
	imports: [
		BrowserModule,
		HttpClientModule,
		HttpClientXsrfModule.withOptions({
			cookieName: 'XSRF-TOKEN',
			headerName: 'X-XSRF-TOKEN',
		}),
		DateFnsModule.forRoot(),
		ImageCropperModule,
		ReactiveFormsModule,
		RouterModule.forRoot([
			// * LAZY LOADER FOR WRITE ROUTES
			{
				path: 'write/:id',
				loadChildren: () => import('@app/write/write.mod').then((m) => m.WriteModule),
			},
			// * LAZY LOADER FOR HOME ROUTES
			{
				path: '',
				pathMatch: 'full',
				loadChildren: () => import('@app/*home/home.mod').then((m) => m.HomeModule),
			},
			// ! FAILURE HANDLER ( CATCH-ALL FALLBACK )
			{ path: '**', component: FailComponent },
		]),
	],
	providers: [
		AuthService,
		AuthGuard,
		{ provide: HTTP_INTERCEPTORS, useClass: AuthRequest, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: AuthXSRF, multi: true },
		//{ provide: HttpXsrfTokenExtractor, useClass: HttpXsrfCookieExtractor }
		// {
		// 	provide:	HTTP_INTERCEPTORS,
		// 	useClass:	AuthRequest,
		// 	multi:		true
		// },
		// {
		// 	provide:	HTTP_INTERCEPTORS,
		// 	useClass:	AuthErrors,
		// 	multi:		true
		// }
	],
	bootstrap: [AppComponent]
})
export class AppModule {
	constructor(private injector: Injector) {}

	ngDoBootstrap() {
		const icon = createCustomElement(CoreIconWidget, { injector: this.injector });
		customElements.define('core-icon', icon);
	}
}
