// REQUIRED - ANGULAR
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpXsrfTokenExtractor } from "@angular/common/http";

@Injectable()
export class AuthRequest implements HttpInterceptor {
	constructor() {}

	intercept( req:HttpRequest<any>, next: HttpHandler ): Observable<HttpEvent<any>> {

		return next.handle(req).pipe(
			// ! PROCESS ERRORS AND FILTER OUT NOISE
			catchError( (err:HttpErrorResponse) => {
				// * DROP ERROR LOGS ON PING REQUESTS
				if ( (req.method === "GET") && (req.url === "/api") ) {
					// ? DO NOTHING
				// ! THROW AN ERROR FOR ANYTHING NOT FILTERED
				} else {
					//console.log('DOBUTTS')
					// ? DO SOMETHING
				}
				return throwError(err);

				
			})
		) as Observable<HttpEvent<any>>;
	}
}

@Injectable()
export class AuthXSRF implements HttpInterceptor {
	constructor(private tokenExtractor: HttpXsrfTokenExtractor) {
	}
	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const cookieheaderName = 'X-XSRF-TOKEN';
		let csrfToken = this.tokenExtractor.getToken() as string;
		if (csrfToken !== null && !req.headers.has(cookieheaderName)) {
			req = req.clone({ headers: req.headers.set(cookieheaderName, csrfToken) });
		}
		return next.handle(req);
	}
}

// HELPER - AUTH TOKENS
// @Injectable()
// export class AuthRequest implements HttpInterceptor {

// 	private auth_error		= '';
// 	private auth_refresh	= false;

// 	private authorise( request: HttpRequest<any>, access: string ) {

// 		return request.clone({
// 			setHeaders: {
// 				Authorization: `Bearer ${ access }`
// 			}
// 		});
// 	}

// 	constructor(
// 		private auth_service:	AuthService
// 	) { }

// 	intercept( request: HttpRequest<any>, next: HttpHandler ): Observable<HttpEvent<any>> {

// 		// NOTE: ADD AUTHORISATION HEADER WITH JWT TOKEN
// 		let auth_user = this.auth_service.auth_user_value;

// 		if ( auth_user && auth_user.access ) {

// 			//console.log( 'DBUG, AUTH, Stored Tokens: ', auth_user );
// 			request	= this.authorise( request, auth_user.access );

// 		}

// 		return next.handle( request ).pipe( catchError( error => {

// 			// NOTE: HANDLE AUTHORISATION FAILURE
// 			if ( error.status === 401 ) {

// 				// NOTE: ATTEMPT TO REFRESH ACCESS TOKEN
// 				if ( !this.auth_refresh ) {

// 					this.auth_refresh	= true;

// 					return this.auth_service.refresh( auth_user.refresh ).pipe(
// 						switchMap( (tokens: any) => {
// 							this.auth_refresh	= false;
// 							return next.handle( this.authorise( request, tokens.access ) );
// 						})
// 					);

// 				// NOTE: REFRESH TOKEN IS EXPIRED
// 				} else {

// 					console.log( 'DBUG, AUTH, Call to renew the refresh token has been made!');

// 					return this.auth_service.auth_user.pipe(
// 						filter( tokens => tokens != null ),
// 						take( 1 ),
// 						switchMap( token => {
// 							return next.handle( this.authorise( request, token.access ) );
// 						})
// 					)
// 				}

// 			} else {

// 				// THROW ERROR
// 				return throwError( error );

// 			}
// 		}));
// 	}
// }
