import { Component, EventEmitter, Input, Output, ViewEncapsulation } from "@angular/core";

/* CORE = ICON */
@Component({
	//encapsulation:  ViewEncapsulation.Native,
	selector:	'core-icon',
	styles:		[],
	template:	`<div class="core-icon" [attr.data-icon]="icon" [attr.data-size]="size" [attr.data-tone]="tone"></div>`
})
export class CoreIconWidget {
	@Input() icon: string;
	@Input() size: string;
	@Input() tone: string;
}