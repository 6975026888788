/* ENVIRONMENT GLOBALS */
//const API = `${process.env.WEB_HOST}:${process.env.WEB_PORT}`;
const WEB = `quillery.app`;

export const environment = {
	production:		false,

	API_AUTH:		`https://${WEB}/api/auth`,
	API_BOOK:		`https://${WEB}/api/book`,
	API_LANG:		`https://${WEB}/api/lang`,
	API_LOGS:		`https://${WEB}/api/logs`,
	API_NEWS:		`https://${WEB}/api/news`,
	API_USER:		`https://${WEB}/api/user`,

	API_SOCK:		`wss://${WEB}/api/sock`,

	WEB_ROOT:		`https://${WEB}`,

	LOG_BROWSE:		9,
	LOG_CLIENT:		9,
	LOG_SERVER:		9,
};
