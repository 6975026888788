// ? EXTERNAL
import { Component, ElementRef, OnInit } from '@angular/core';

// ? INTERNAL
import { environment as env } from '@env/env';

/* CORE = ROOT COMPONENT */
@Component({ selector: 'stry-root', templateUrl: './app.component.html', styleUrls: [ './app.component.scss' ] })
export class AppComponent implements OnInit {
	constructor(
		private _elem: ElementRef
	) {}

	title = 'STRY';

	// ? ON ROOT STACK INITIALISATION
	ngOnInit(): void {
		// * RECOVER APPLICATION THEME STATE
		let dark = window.matchMedia("(prefers-color-scheme: dark)");	// ? TEST SYSTEM COLOUR SCHEME SETTINGS
		let mode = window.localStorage.getItem('sess.user.luna');		// ? RECOVER STORED STATE
		if (mode) { mode = (mode === 'true') ? 'luna' : 'sola' };		// ? CONVERT BOOL STATE TO STRING STATE
		if (!mode) { mode = dark.matches ? 'luna' : 'sola'; };			// ? FAILBACK TO SYSTEM STATE IF NOTHING RECOVERED
		document.body.classList.add(mode);								// ? ADD COLOUR SCHEME TO DOCUMENT FOR VARIABLE PROCESSOR
		window.localStorage.setItem('sess.user.luna', (mode === 'luna') ? 'true' : 'false'); // ? STORE SCHEME STATE
		// ! STRIP VERSION INFORMATIONS FROM PRODUCTION BUILDS
		if (env.production) { this._elem.nativeElement.removeAttribute("ng-version") }; // ? DROP NG-VERSION FROM PRODUCTION
	}
}
